import {
  faInfoCircle,
  faListAlt,
  faShieldAlt,
  faUserCheck
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import IconAlert from 'components/common/IconAlert';
import SoftBadge from 'components/common/SoftBadge';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import {
  Alert,
  Card,
  Dropdown,
  OverlayTrigger,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import { FaApple, FaGooglePlay } from 'react-icons/fa';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';

import store from 'app/store';
import segmentHelper from 'helpers/segment-helper';
import { toast } from 'react-toastify';
import { setJournalEntryList } from 'stores/app';
import { showAlert, showConfirm, showViewEntryDialog } from 'stores/modal';
import Colors from 'util/Colors';
import api from 'util/api';
import c from 'util/const';
import filters from 'util/filters';
import logger from 'util/logger';
import NotaryJournalTableHeader from './NotaryJournalTableHeader';
import draftEntryUiHelper from 'helpers/draft-entry-ui-helper';
import userHelper from 'helpers/userHelper';

const Journal = () => {
  const [entries, setEntries] = useState([]);
  const [displayEntries, setDisplayEntries] = useState([]);
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [nextCreatedBefore, setNextCreatedBefore] = useState(null);
  //const [hasMoreResults, sethasMoreResults] = useState(true);
  const [searchString, setSearchString] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [filterMonth, setFilterMonth] = useState({});
  const [updatingChanges, setUpdatingChanges] = useState(false);
  const { userMaster, journalList } = useSelector(state => state.app);

  const dispatch = useDispatch();
  const initialized = useRef(false);
  const hasMoreResults = useRef(false);
  const { subscriptionStatus } = useSelector(state => state.subscription);
  let activeSubscriptions = _.get(subscriptionStatus, 'Active', []);

  useEffect(() => {
    logger.log('calling initializeEntries via useEffect');
    initializeEntries();
  }, []);

  // useEffect(() => {
  //   if (searchString.length > 2) _.debounce(initSearch, 1000);
  //   else if (searchString.length == 0) {
  //     setIsLoadingMore(true);
  //     initializeEntries();
  //   }
  // }, [searchString]);

  useEffect(() => {
    logger.log('searchString', searchString);
    if (searchString?.length > 2) {
      setFilterMonth(null);
      initSearch(true);
    } else if (searchString !== null && searchString?.length == 0) {
      setIsLoadingMore(true);
      initializeEntries(true);
    }
  }, [searchString]);

  useEffect(() => {
    logger.log('filterMonth', filterMonth);
    //setEntries([]);
    if (!_.isEmpty(filterMonth)) {
      dispatch(setJournalEntryList(null)).then(res => {
        //logger.log('journalList1', store.getState().app.journalList);
        //logger.log('journalList2', journalList);

        initSearch(true);
      });
    }
  }, [filterMonth]);

  useEffect(() => {
    setDisplayEntries(doSortEntriesForDisplay(journalList));
  }, [journalList]);

  const initSearch = refresh => {
    setIsLoadingMore(true);
    initializeEntries(refresh);
  };

  const markUpdateItemChangesinEntry = (entry, options) => {
    let entryUpdated = [];
    _.map(displayEntries, d => {
      if (d.GUID === entry.GUID) {
        let newItem = _.merge(d, options);
        entryUpdated.push(newItem);
      }
      entryUpdated.push(d);
    });
    setDisplayEntries(entryUpdated);
  };

  const handleStrikeEntry = entry => {
    if (activeSubscriptions.length === 0) {
      dispatch(
        showAlert(
          'Subscription Required',
          'You have no active subscription and your signings are queued for deletion. Reactivate your subscription in the Subscription tab.'
        )
      );
      return;
    }
    if (entry.Stricken) {
      setUpdatingChanges(true);
      api.Signing.unstrike(entry.GUID)
        .then(res => {
          logger.log('unstrike res', res);
          segmentHelper.track(
            segmentHelper.events.SIGNING_DETAIL_UNSTRIKE_ENTRY
          );
          toast.success(`Signing record marked Unstriken`, {
            theme: 'colored',
            position: 'bottom-center',
            icon: faUserCheck,
            toastId: 'login-success'
          });
          markUpdateItemChangesinEntry(entry, { Stricken: false });
        })
        .catch(err => {
          logger.log('error while saving unstriking entry', err);
          dispatch(
            showAlert(
              'Unable to unstrike entry',
              'There was a problem unstriking your entry.  Please try again.'
            )
          );
        })
        .finally(() => {
          setUpdatingChanges(false);
        });
    } else {
      dispatch(
        showConfirm(
          'Are you sure?',
          'Striking a signing will cross it out in your journal history, but the entry will remain in your journal history.  You will be able to undo this action.',
          res => {
            if (res) {
              setUpdatingChanges(true);
              api.Signing.strike(entry.GUID)
                .then(res => {
                  //logger.log('strike res', res);
                  segmentHelper.track(
                    segmentHelper.events.SIGNING_DETAIL_STRIKE_ENTRY
                  );
                  toast.success(`Signing record marked Striken`, {
                    theme: 'colored',
                    position: 'bottom-center',
                    icon: faUserCheck,
                    toastId: 'login-success'
                  });
                  markUpdateItemChangesinEntry(entry, { Stricken: true });
                })
                .catch(err => {
                  logger.log('error while saving striking entry', err);
                  dispatch(
                    showAlert(
                      'Unable to strike entry',
                      'There was a problem striking your entry.  Please try again.'
                    )
                  );
                })
                .finally(() => {
                  setUpdatingChanges(false);
                });
            }
          }
        )
      );
    }
  };

  const columns = [
    {
      accessor: 'EmailSent',
      Header: 'Mail',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { EmailSent, LocalTimezone } = rowData.row.original;
        return (
          <Flex alignItems="center">
            {!!EmailSent ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Sent mail on:{' '}
                    {draftEntryUiHelper
                      .getDateTimeWithTimeZone(
                        EmailSent,
                        userHelper.getTimezoneVal(LocalTimezone)
                      )
                      .format(c.dateFormat)}
                  </Tooltip>
                }
              >
                <span>
                  <FontAwesomeIcon
                    size="xl"
                    color={Colors.success.color}
                    icon="envelope"
                    className="me-2"
                  />
                </span>
              </OverlayTrigger>
            ) : (
              <FontAwesomeIcon size="xl" icon="envelope" className="me-2" />
            )}
          </Flex>
        );
      }
    },
    {
      accessor: 'SigningTimestamp',
      Header: 'Notarization Date and Time',
      Cell: rowData => {
        const { SigningTimestamp, Stricken, LocalTimezone } =
          rowData.row.original;
        let formattedDate = draftEntryUiHelper
          .getDateTimeWithTimeZone(
            SigningTimestamp,
            userHelper.getTimezoneVal(LocalTimezone)
          )

          .format(c.dateFormat);
        return (
          <span
            className={Stricken ? 'text-danger' : ''}
            onClick={() => onRowClicked(rowData.row.original)}
            style={Stricken ? { textDecoration: 'line-through' } : {}}
          >
            {formattedDate}
          </span>
        );
      }
    },
    {
      accessor: 'Signers',
      Header: 'Signer',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { Signers, Stricken } = rowData.row.original;
        let signerName = Signers ? filters.buildSignerString(Signers) : '';
        return (
          <Flex
            alignItems="center"
            onClick={() => onRowClicked(rowData.row.original)}
          >
            <Avatar
              size="xl"
              name={signerName.substring(0, 10)}
              className="me-2"
              mediaClass={Stricken ? 'bg-danger' : ''}
            />
            <div className="flex-1">
              <h5
                className={Stricken ? 'mb-0 fs--1 text-danger' : 'mb-0 fs--1'}
                style={Stricken ? { textDecoration: 'line-through' } : {}}
              >
                {userMaster.PrivacyEnabled
                  ? signerName.replace(/[a-zA-Z0-9]/g, '*').substring(0, 10)
                  : signerName}
              </h5>
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'NotaryEntries',
      Header: 'Entries',
      Cell: rowData => {
        const { NotaryEntries, Stricken } = rowData.row.original;
        return (
          <span
            className={Stricken ? 'text-danger' : ''}
            style={Stricken ? { textDecoration: 'line-through' } : {}}
            onClick={() => onRowClicked(rowData.row.original)}
          >
            {NotaryEntries.length} Document
            {NotaryEntries?.length === 1 ? '' : 's'}
          </span>
        );
      }
    },
    {
      accessor: 'NotaryFee',
      Header: 'Notary Fee',
      Cell: rowData => {
        const { NotaryFee, NotaryFeeCurrency, Stricken } = rowData.row.original;
        return (
          <span
            className={Stricken ? 'text-danger' : ''}
            style={Stricken ? { textDecoration: 'line-through' } : {}}
            onClick={() => onRowClicked(rowData.row.original)}
          >
            { NotaryFee === 0
            ? '$0'
            : NotaryFee
              ? userMaster.PrivacyEnabled
                ? '****'
                : filters.formatCurrencyFromServer(NotaryFee, NotaryFeeCurrency)
              : '- None entered -'}
          </span>
        );
      }
    },
    {
      accessor: 'State',
      Header: 'Address',
      Cell: rowData => {
        const { State, PostalCode, Stricken } = rowData.row.original;
        return (
          <span
            className={Stricken ? 'text-danger' : ''}
            style={Stricken ? { textDecoration: 'line-through' } : {}}
          >
            {State} - {PostalCode}
          </span>
        );
      }
    },

    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { Stricken } = rowData.row.original;
        return (
          <CardDropdown drop='start' iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item
                href="#!"
                onClick={() => onRowClicked(rowData.row.original)}
              >
                View
              </Dropdown.Item>
              <Dropdown.Item
                href="#!"
                onClick={() => handleStrikeEntry(rowData.row.original)}
              >
                {Stricken ? 'Unstrike' : 'Strike'}
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  const onRowClicked = entry => {
    if (activeSubscriptions.length === 0) {
      dispatch(
        showAlert(
          'Subscription Required',
          'You have no active subscription and your signings are queued for deletion. Reactivate your subscription in the Subscription tab.'
        )
      );
      return;
    }

    dispatch(
      showViewEntryDialog(entry.GUID, res => {
        if (res) {
          initializeEntries();
        }
      })
    );
  };

  const initializeEntries = refresh => {
    if (!loading && (!initialized.current || refresh)) {
      initialized.current = true;
      logger.log('Calling initializeEntries', loading);
      setLoading(true);
      return new Promise((resolve, reject) => {
        //setEntries([]);
        //setDisplayEntries([]);
        //sethasMoreResults(false);
        dispatch(setJournalEntryList(null)).then(res => {
          hasMoreResults.current = false;
          loadNextPage(refresh)
            .then(res => {
              setLoading(false);
              if (hasMoreResults.current) {
                loadNextPage();
              } else {
                return Promise.resolve(true);
              }
            })
            .then(() => {
              logger.log('loadNextPage finished');
              resolve(true);
            })
            .catch(err => {
              setLoading(false);
              logger.log('error loading initial page', err);
              //this.setState({ loadError: err });
              resolve(false);
            });
        });
      });
    }
  };

  const loadNextPage = refresh => {
    if (hasSearchParams()) {
      return loadNextPageSearch(refresh);
    } else {
      return loadNextPageNoSearch(refresh);
    }
  };

  const hasSearchParams = () => {
    return getParsedSearchTerms().length > 0;
  };

  const doSortEntriesForDisplay = entries => {
    logger.log('Sorting entries', entries);
    //Watch for changes here.  This should reflect what is displayed, in DashboardItem.
    return _.sortBy(entries, entry => {
      if (entry.SigningTimestamp) {
        return moment(entry.SigningTimestamp).unix();
      } else if (entry.Completed) {
        return -moment(entry.Completed).unix();
      } else {
        return -moment(entry.Created).unix();
      }
    });
  };

  const loadNextPageSearch = async () => {
    setLoading(true);
    setIsLoadingMore(true);
    try {
      try {
        const res = await api.Search.searchPaging(
          nextCreatedBefore ? nextCreatedBefore : moment().format(),
          getParsedSearchTerms()
        );
        logger.log('got entries refreshEntriesWithSearch', res);
        let existingJournalList = store.getState()?.app?.journalList || [];
        let updatedEntryList = _.uniqBy(
          [...existingJournalList, ...res.Signings],
          'GUID'
        );
        //setEntries(updatedEntryList);
        dispatch(setJournalEntryList(updatedEntryList));
        setNextCreatedBefore(res.NextCreatedBefore || null);
        //sethasMoreResults(!res.EndOfResults);
        hasMoreResults.current = !res.EndOfResults;
        logger.log('loadNextPageSearch', res.length, pageSize);
        setDisplayEntries(doSortEntriesForDisplay(updatedEntryList)).then(
          () => {
            setLoading(false);
            setIsLoadingMore(false);
          }
        );
      } catch (err) {
        logger.log('error loading notary entries', err);
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const loadNextPageNoSearch = async refresh => {
    setIsLoadingMore(true);
    logger.log('loadNextPageNoSearch Start', refresh);
    let lastCreatedOnDate = getLastCreatedOnDateFromResults(refresh);
    logger.log('loadNextPageNoSearch  lastCreatedOnDate', lastCreatedOnDate);
    return api.Signing2.listPaging(
      pageSize,
      filterMonth
        ? refresh
          ? filterMonth.end
          : lastCreatedOnDate
          ? lastCreatedOnDate.format()
          : null
        : refresh
        ? null
        : lastCreatedOnDate.format(),
      filterMonth ? filterMonth.begin : null
    ).then(res => {
      logger.log('loadNextPageNoSearch got page of entries', res.length);
      logger.log('loadNextPageNoSearch existing page of entries', journalList);
      let existingJournalList = store.getState()?.app?.journalList || [];
      let updatedEntryList = refresh
        ? _.uniqBy(res, 'GUID')
        : res
        ? _.uniqBy([...existingJournalList, ...res], 'GUID')
        : _.uniqBy(existingJournalList, 'GUID');

      logger.log(
        'loadNextPageNoSearch length, size',
        updatedEntryList.length,
        pageSize
      );
      //sethasMoreResults(res.length === pageSize);
      hasMoreResults.current = res.length === pageSize;
      setIsLoadingMore(false);
      //setEntries(updatedEntryList);
      dispatch(setJournalEntryList(updatedEntryList));
      //setDisplayEntries(doSortEntriesForDisplay(updatedEntryList));
    });
  };

  const getParsedSearchTerms = () => {
    let parsed = _.trim(searchString);
    parsed.toLowerCase();

    if (parsed && parsed.length > 0) {
      return [parsed];
    } else {
      return [];
    }
  };

  const getLastCreatedOnDateFromResults = refresh => {
    let lastCreatedOnDate = null;
    _.each(store.getState()?.app?.journalList, e => {
      if (!lastCreatedOnDate) {
        lastCreatedOnDate = moment(e.SigningTimestamp);
      }

      let thisCreated = moment(e.SigningTimestamp);
      if (thisCreated.isBefore(lastCreatedOnDate)) {
        lastCreatedOnDate = thisCreated;
      }
    });
    if (filterMonth && refresh) {
      lastCreatedOnDate = moment(filterMonth.end).isBefore(lastCreatedOnDate)
        ? lastCreatedOnDate
        : moment(filterMonth.end);
    }
    return lastCreatedOnDate;
  };

  const fetchMoreData = () => {
    logger.log('fetchMoreData called!!!!');
    loadNextPage().catch(err => {
      logger.log('error loading next page', err);
    });
  };

  const IconAlertCode = function IconAlertDemo({ variant, message }) {
    if (show) {
      return (
        <IconAlert variant={variant} dismissible onClose={() => setShow(false)}>
          <p className="mb-0">{message}</p>
        </IconAlert>
      );
    }
  };

  return (
    <>
      {!loading && activeSubscriptions.length === 0 && (
        <div className="pb-3">
          <Alert variant="warning">
            <Alert.Heading>
              <FontAwesomeIcon icon={faInfoCircle} /> Subscription Required
            </Alert.Heading>
            <p>
              You have no active subscription and your signings are queued for
              deletion. Reactivate your subscription in the Subscription tab.
            </p>
          </Alert>
        </div>
      )}

      {
        <AdvanceTableWrapper
          columns={columns}
          data={displayEntries}
          selection={false}
          sortable
          pagination
          perPage={100000000}
          sortBy={[
            {
              id: 'Created',
              desc: true
            }
          ]}
        >
          <Card className="mb-3">
            <Card.Header>
              <NotaryJournalTableHeader
                table
                heading={
                  userMaster?.PrivacyEnabled ? (
                    <>
                      Journal notary list{' '}
                      <SoftBadge pill bg="success" className="me-2">
                        <FontAwesomeIcon
                          size="sm"
                          color={Colors.success.color}
                          icon={faShieldAlt}
                          className="me-0"
                        />{' '}
                        Privacy mode
                      </SoftBadge>
                    </>
                  ) : (
                    'Journal entries'
                  )
                }
                searchString={searchString}
                setSearchString={setSearchString}
                filterMonth={filterMonth}
                setFilterMonth={setFilterMonth}
              />
            </Card.Header>
            <Card.Body className="p-0">
              {/* <IconAlertCode variant="info" message="Any saved drafts you may have are stored on your device only, until they are completed and uploaded to our servers." /> */}
              <IconAlertCode
                variant="info"
                message="Your journal is sequential based on the date & time you selected when creating a new entry. If you did not manually select a date & time while creating the entry, we use the 'completed' time stamp of the entry."
              />

              <InfiniteScroll
                dataLength={displayEntries.length}
                next={activeSubscriptions.length > 0 ? fetchMoreData : false}
                hasMore={hasMoreResults.current}
                //refreshFunction={() => initializeEntries()}
                //pullDownToRefresh
                //pullDownToRefreshThreshold={50}
                loader={
                  <Flex
                    justifyContent="center"
                    alignItems={'center'}
                    className="mt-3"
                  >
                    <Spinner size="sm" variant="dark" /> &nbsp; Loading more...
                  </Flex>
                }
              >
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName={`align-middle white-space-nowrap ${
                    activeSubscriptions.length == 0 && 'block-text-blur'
                  }`}
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                  onRowClicked={onRowClicked}
                />
              </InfiniteScroll>
            </Card.Body>
            <Card.Footer>
              <>
                {loading ? (
                  <Flex justifyContent="center">
                    <Spinner size="sm" />
                  </Flex>
                ) : (
                  <></>
                )}
              </>
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      }

      {!loading &&
        journalList?.length === 0 &&
        !hasSearchParams() &&
        !isLoadingMore && (
          <>
            <Card className="text-center py-3 mb-3">
              <Card.Body className="p-0">
                <div className="text-center">
                  <FontAwesomeIcon
                    className="text-muted"
                    icon={faListAlt}
                    size="3x"
                  />
                  <br />
                  <p className="my-3">You have no journal entries.</p>
                </div>
              </Card.Body>
            </Card>
            <Card className="text-center py-5">
              <Card.Body className="p-0">
                <h4 className="display-5">
                  Welcome to The Notary eJournal, by Jurat inc
                </h4>
                <p className="pt-3">
                  It looks like you haven't created any Journal Entries yet.
                  Once you do, they will show up here and you can view them.
                </p>
                <div className="text-center mt-3">
                  <div className="text-muted">
                    If you haven't already, you can download the app below.
                  </div>
                  <Flex
                    direction="row"
                    justifyContent="center"
                    className="mt-3"
                  >
                    <div className="text-center mb-2 me-2">
                      <button
                        className="btn btn-outline-dark btn-icon-text"
                        onClick={() => window.open(c.mobileApp.store.ios)}
                      >
                        <FaApple
                          className="btn-icon-prepend mr-2"
                          style={{
                            verticalAlign: 'baseline',
                            fontSize: '40px'
                          }}
                        />
                        <span className="d-inline-block text-left">
                          {' '}
                          <small className="font-weight-light d-block">
                            Available on the
                          </small>{' '}
                          App Store{' '}
                        </span>
                      </button>
                    </div>

                    <div className="text-center mb-2 me-2">
                      <button
                        className="btn btn-outline-dark btn-icon-text"
                        onClick={() => window.open(c.mobileApp.store.android)}
                      >
                        <FaGooglePlay
                          className="btn-icon-prepend mr-2"
                          style={{
                            verticalAlign: 'baseline',
                            fontSize: '40px'
                          }}
                        />
                        <span className="d-inline-block text-left">
                          {' '}
                          <small className="font-weight-light d-block">
                            Get it on
                          </small>{' '}
                          Google Play{' '}
                        </span>
                      </button>
                    </div>
                  </Flex>
                </div>
              </Card.Body>
            </Card>
          </>
        )}
    </>
  );
};

export default Journal;
